@import '../../../../scss/theme-bootstrap';

.editorial-grid-formatter {
  display: block;
  .page-product & {
    padding: 0;
  }
  &__container {
    padding: 0;
    position: relative;
  }
  &__cta {
    width: 100%;
    &.content-block__links {
      margin-top: 40px;
      padding-bottom: 0;
      @media #{$cr19-large-up} {
        margin-top: 48px;
        padding-bottom: 0;
      }
    }
  }
  // Default state - flex grid
  &__grid {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    @media #{$cr19-large-up} {
      width: auto;
      margin: 0 -12px;
    }
    > div {
      padding: 12px 0;
      @media #{$cr19-large-up} {
        padding: 0 12px 12px;
      }
    }
    // Grid column options
    &.grid-columns--mob-1 {
      > div {
        width: 100%;
      }
    }
    &.grid-columns--mob-2 {
      > div {
        width: 50%;
      }
    }
    &.grid-columns--mob-1,
    &.grid-columns--mob-2 {
      // PC specific
      @media #{$cr19-large-up} {
        &.grid-columns--pc-1 {
          > div {
            width: 100%;
          }
        }
        &.grid-columns--pc-2 {
          > div {
            width: 50%;
          }
        }
        &.grid-columns--pc-3 {
          > div {
            width: 33.333%;
          }
        }
        &.grid-columns--pc-4 {
          > div {
            width: 25%;
          }
        }
        &.grid-columns--pc-5 {
          > div {
            width: 20%;
          }
        }
      }
    }
  }
  // Carousel version
  &__carousel {
    width: 100%;
    .editorial-grid-formatter__item {
      > div {
        display: none; // fight the slick FOUC
      }
    }
    &.slick-slider {
      touch-action: inherit;
    }
    &.slick-initialized {
      .editorial-grid-formatter__item {
        > div {
          display: block;
          margin-bottom: 10px;
          @media #{$cr19-large-up} {
            margin-bottom: 40px;
          }
        }
      }
    }
    .slick-list {
      @media #{$cr19-large-up} {
        margin-#{$ldirection}: -12px;
        margin-#{$rdirection}: -12px;
      }
    }
    &.carousel--peeking {
      margin: 0 -10px;
      @media #{$cr19-large-up} {
        margin: 0;
      }
      .slick-list {
        @include swap_direction(padding, 0 15% 0 0);
        margin-#{$rdirection}: -15%;
        @media #{$cr19-large-up} {
          margin-#{$ldirection}: -12px;
          margin-#{$rdirection}: -12px;
          padding: 0;
        }
      }
    }
    .slick-track {
      > div {
        padding: 0 10px;
        @media #{$cr19-large-up} {
          padding: 0 12px;
        }
      }
    }
    // Carousel slide options
    &.carousel-slides--mob-1,
    &.carousel-slides--pc-1 {
      .slick-track {
        > div {
          width: 100%;
        }
      }
    }
    &.carousel-slides--mob-2,
    &.carousel-slides--pc-2 {
      .slick-track {
        > div {
          width: 50%;
        }
      }
    }
    @media #{$cr19-large-up} {
      &.carousel-slides--pc-3 {
        .slick-track {
          > div {
            width: 33.333%;
          }
        }
      }
      &.carousel-slides--pc-4 {
        .slick-track {
          > div {
            width: 25%;
          }
        }
      }
    }
  }
  &__carousel-controls {
    height: 0;
    #{$ldirection}: 0;
    position: absolute;
    top: 25%;
    width: 100%;
    z-index: 1;
    @media #{$cr19-large-up} {
      top: 45%;
    }
  }
  &__carousel-dots {
    width: 100%;
    @media #{$cr19-large-up} {
      #{$ldirection}: 0;
      position: absolute;
      top: 86%;
      z-index: 1;
    }
    .slick-dots {
      bottom: 0;
      li {
        height: 8px;
        width: 8px;
      }
      button {
        height: 8px;
        width: 8px;
        &::before {
          height: 8px;
          font-size: 8px;
          line-height: 17px;
          width: 8px;
        }
      }
    }
  }
  &-carousel-arrow {
    background: transparent;
    border: none;
    cursor: pointer;
    height: 24px;
    position: absolute;
    width: 24px;
    padding-#{$rdirection}: 28px;
    svg {
      width: 24px;
      height: 24px;
    }
    &.previous {
      #{$ldirection}: 1%;
      transform: rotate(-180deg);
    }
    &.next {
      #{$rdirection}: 1%;
      transform: rotate(360deg);
    }
    &.slick-disabled {
      cursor: default;
      opacity: 0.25;
    }
  }
  &__item {
    .hero-block__media-wrapper + .hero-block__content-below-media-wrapper {
      .content-block__links {
        padding-top: 1.5rem;
      }
    }
  }
}
